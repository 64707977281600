<template>
  <div
    class="account-orders-list"
    :id="paginationId"
  >
    <h2 class="account-orders-list__headline h2">
      {{ $t('account.orders.headline') }}
    </h2>
    <span>
      {{ $t('account.orders.message') }}
    </span>
    <div
      v-if="noAddressData && orders"
      class="account-orders-list__extra-space"
    >
      {{ $t('account.data.empty') }}
    </div>
    <template v-if="orders">
      <div class="account-orders-list__content">
        <div class="account-orders-list__orders">
          <div
            v-for="(order, i) in orders"
            :key="i"
            class="account-orders-list__order-wrapper"
          >
            <div class="account-orders-list__order-content">
              <div class="account-orders-list__order-header">
                <div>
                  <div class="default-semi-bold">
                    {{ $t('account.order.headline', [toLocaleDateString(order.orderDate)]) }}
                  </div>
                  <div
                    v-if="!containsMultipleOrders(order)"
                    class="small-regular"
                  >
                    {{ $t('account.order.subheadline', [order.accountOrderLines[0].orderNumber]) }}
                    <span
                      v-if="order.accountOrderLines[0].invoiceId"
                      class="small-regular"
                    >
                      ({{ order.accountOrderLines[0].invoiceId }})
                    </span>
                    <div
                      v-if="isOriginInfoEnable"
                    >
                      {{ $t('ads.product.info.origin') }}
                      <span>{{ checkOrigin(order.sellerOrigin) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <p>
                {{ omsServiceAgentLoggedInAs }}
              </p>
              <div class="account-orders-list__order">
                <div class="account-orders-list__order-images">
                  <template v-for="(image, j) in order.images">
                    <span
                      v-if="j < 2"
                      :key="`image-${i}-${j}`"
                    >
                      <img
                        class="account-orders-list__order-image"
                        :src="imageURL(image.src, imageSizeM())"
                        :alt="image.alt"
                        :title="image.title"
                      >
                    </span>
                  </template>
                  <span
                    v-if="order.images.length > 2"
                    class="account-orders-list__order-images--counter"
                  >
                    + {{ remainingImagesCounter(order.images.length) }}<br>
                    <template v-if="remainingImagesCounter(order.images.length) === 1">
                      {{ $t('global.article.label') }}
                    </template>
                    <template v-else>
                      {{ $t('global.articles.label') }}
                    </template>
                  </span>
                </div>
                <div
                  v-if="!containsMultipleOrders(order)"
                  class="account-orders-list__order-price default-bold"
                >
                  {{ order.accountOrderLines[0].grandTotal.displayValue }}
                </div>
                <div class="account-orders-list__order-button-wrapper">
                  <div
                    v-if="!containsMultipleOrders(order) && !isTablet"
                    class="account-orders-list__order-button-wrapper__invoice"
                  >
                    <button
                      :disabled="!order.accountOrderLines[0].invoiceId ||
                       isInvoiceLoading[order.accountOrderLines[0].orderNumber] || disableButton[order.accountOrderLines[0].orderNumber]"
                      class="button button--outlined-default | account-orders-list-details__order-button account-orders-list-details__order-button-download"
                      @click="getInvoicePdf(order.accountOrderLines[0].orderNumber)"
                      :style="{ cursor: isInvoiceLoading[order.accountOrderLines[0].orderNumber] ? 'default' : 'pointer' }"
                    >
                      {{ isInvoiceLoading[order.accountOrderLines[0].orderNumber] ? $t('account.invoice.loading')
                          : $t('account.invoice.button') }}
                    </button>
                    <div
                      :id="`account.invoice.button${order.accountOrderLines[0].orderNumber}`"
                      style="display: none;text-align: end"
                    >
                      {{ $t('account.invoice.download.error') }}
                    </div>
                  </div>
                  <button
                    class="account-orders-list__order-button account-orders-list__order-button-arrow | button button--filled-default"
                    v-t="'account.details.button'"
                    @click="goToOrder(order.accountOrderLines)"
                  />
                </div>
              </div>
              <div
                v-if="containsMultipleOrders(order)"
                class="account-orders-list__order-details"
              >
                <template
                  v-for="(details, orderLineIndex) in order.accountOrderLines"
                  :key="`order-details-${details.orderNumber}-${orderLineIndex}`"
                >
                  <account-orders-list-details :order-line="details"/>
                </template>
              </div>
            </div>
            <div
              class="account-orders-list__order-button-mobile-arrow"
              @click="goToOrder(order.accountOrderLines)"
            />
          </div>
        </div>
      </div>
      <div
        class="pagination__bottom-bar"
      >
        <pagination
          :pagination="pagingData"
          :show-go-to="false"
          :show-items-per-page="true"
          :max-size="totalOrdersSize.toString()"
          :id="paginationId"
          @next-page="nextPageHandler"
          @prev-page="prevPageHandler"
          @elements-per-page="elementsPerPageHandler"
        />
      </div>
    </template>
    <template v-else>
      <p v-if="!noAddressData">
        {{ $t('account.orders.headline.empty') }}
      </p>
    </template>
    <div v-if="noAddressData && !orders">
      {{ $t('account.data.empty') }}
    </div>
  </div>
</template>

<script>
import globalStateComputedMixin from '../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin'
import Pagination from '../pagination/pagination.vue'
import {breakpoints} from '../../variables/variables'
import DateFormatMixin from '../../utilities/vueMixins/dateFormatMixin/dateFormatMixin'
import accountPageStateComputedMixin from '../../utilities/vueMixins/account/accountPageStateComputedMixin'
import {mapGetters} from 'vuex'
import {toRaw} from 'vue'
import AccountOrdersListDetails from './accountOrdersListDetails.vue'
import DateInput from '../dateInput/dateInput.vue'
import accountOrderMixin from '../../utilities/vueMixins/account/accountOrderMixin'
import {imageSizeM, imageUrlWithParam} from '../../utilities/js/image/image';

export default {
  components: {
    DateInput,
    AccountOrdersListDetails,
    Pagination
  },
  mixins: [
    globalStateComputedMixin,
    accountPageStateComputedMixin,
    DateFormatMixin,
    accountOrderMixin
  ],
  props: {
    noAddressData: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.bindHistoryEvent()
    this.saveInitialHistoryState()
  },
  emits: ['goToOrder'],
  data() {
    return {
      isTablet: window.innerWidth <= breakpoints.laptop,
      paginationId: 'orderHistory',
      isInvoiceLoading: {},
      disableButton: {}
    }
  },
  computed: {
    ...mapGetters('accountPage', [])
  },
  methods: {
    goToOrder(orderLines) {
      const orderNumbers = orderLines.map(orderLine => orderLine.orderNumber).join()
      this.$emit('goToOrder', orderNumbers);
    },
    imageSizeM() {
      return imageSizeM
    },
    nextPageHandler() {
      this.$store.dispatch('accountPage/nextPage')
    },
    prevPageHandler() {
      this.$store.dispatch('accountPage/prevPage')
    },
    elementsPerPageHandler(payload) {
      let allOrders = false
      if (payload.value === this.totalOrdersSize.toString()) {
        allOrders = true
      }
      payload.allOrders = allOrders
      this.$store.dispatch('accountPage/itemsPerPage', payload)
    },
    bindHistoryEvent() {
      window.onpopstate = (event) => {
        this.$store.dispatch('accountPage/historyPopState', event)
      }
    },
    saveInitialHistoryState() {
      window.history.replaceState(
        {
          orders: toRaw(this.$store.state.accountPage.orders),
          pagingData: toRaw(this.$store.state.accountPage.pagingData)
        },
        document.title,
        window.location.href
      )
    },
    remainingImagesCounter(length) {
      return length > 2 ? length - 2 : 0;
    },
    imageURL(src, width) {
      return imageUrlWithParam(src, width)
    },
    containsMultipleOrders(orderHeader) {
      return orderHeader.accountOrderLines.length > 1
    }
  }
}
</script>
