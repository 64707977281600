<template>
  <div
    class="photo-swipe-gallery | pswp"
    ref="mainFullScreenGallery"
    :id="id"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
    v-if="initPhotoSwipe"
  >

    <div class="pswp__bg"/>
    <div class="pswp__scroll-wrap">
      <div class="pswp__container">
        <div class="pswp__item"/>
        <div class="pswp__item"/>
        <div class="pswp__item"/>
      </div>
      <div class="pswp__ui pswp__ui--hidden">
        <div class="pswp__top-bar">
          <div class="pswp__counter"/>
          <button
            class="pswp__button pswp__button--close"
            :title="returnButtonLabel('close')"
          />
          <button
            class="pswp__button pswp__button--fs"
            :title="returnButtonLabel('toggle')"
          />
          <button
            class="pswp__button pswp__button--zoom"
            :title="returnButtonLabel('zoom')"
          />
          <button
            class="pswp__button pswp__button--share"
            title="Share"
          />
          <div class="pswp__preloader">
            <div class="pswp__preloader__icn">
              <div class="pswp__preloader__cut">
                <div class="pswp__preloader__donut"/>
              </div>
            </div>
          </div>
        </div>
        <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
          <div class="pswp__share-tooltip"/>
        </div>
        <button
          class="pswp__button pswp__button--arrow pswp__button--arrow--left"
          :title="returnButtonLabel('arrowLeft')"
        />
        <button
          class="pswp__button pswp__button--arrow pswp__button--arrow--right"
          :title="returnButtonLabel('arrowRight')"
        />
        <div class="pswp__caption">
          <div class="pswp__caption__center"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// HTML structure is required by PhotoSwipe
import globalStateComputedMixin
  from "../../utilities/vueMixins/globalStateComputedMixin/globalStateComputedMixin";
import PhotoSwipe from 'photoswipe/dist/photoswipe.js';
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default.js'
import {imageSizeXL, imageUrlWithParam} from "../../utilities/js/image/image";

const PhotoSwipeGallery = {
  name: 'photo-swipe-gallery',
  mixins: [
    globalStateComputedMixin
  ],
  props: {
    id: String,
    productName: String,
    imageVersions: Array,
    initPhotoSwipe: {
      type: Boolean,
      default: true
    }
  },
  created() {
    this.$store.subscribe(mutation => {
      if (mutation.type === 'initPhotoSwipe' && this.id === mutation.payload.galleryId) {
        this.initGallery(this.currentGalleryIndex())
      }
    });
  },
  methods: {
    initGallery(index) {
      let options = {
        index: index,
        showHideOpacity: true,
        bgOpacity: 0.8,
        spacing: 0.1,
        shareEl: false,
        closeEl: true,
        captionEl: true,
        fullscreenEl: true,
        zoomEl: true,
        loop: false,
        history: false,
        preloaderEl: false,
        preload: [1, 1],
        getDoubleTapZoom: (isMouseClick, image) => {
          return image.initialZoomLevel * 2;
        },
      };

      let items = [];
      let imagesLoaded = 0;
      let totalImages = this.imageVersions.length;

      this.imageVersions.forEach((image, i) => {
        let img = new Image();
        img.onload = () => {
          items[i] = {
            src: imageUrlWithParam(image.zoom, imageSizeXL),
            title: this.productName,
            w: img.naturalWidth,
            h: img.naturalHeight
          };
          imagesLoaded++;
          if (imagesLoaded === totalImages) {
            this.initializePhotoSwipe(items, options);
          }
        };
        img.src = imageUrlWithParam(image.zoom, imageSizeXL);
      });
    },
    initializePhotoSwipe(items, options) {
      items = items.filter(item => item.w > 0 && item.h > 0);
      if (this.$refs.mainFullScreenGallery) {
        this.gallery = new PhotoSwipe(this.$refs.mainFullScreenGallery, PhotoSwipeUI_Default, items, options);
        this.gallery.init();

        this.gallery.listen('unbindEvents', () => {
          this.$store.dispatch('indexUpdate', {
            showImage: this.gallery.getCurrentIndex(),
            galleryId: this.id
          });
        });
      }
    },
    returnButtonLabel(label) {
      let buttonTitle;

      switch (label) {
        case 'toggle':
          buttonTitle = this.$t('photoSwipe.toggle');
          break;
        case 'close':
          buttonTitle = this.$t('photoSwipe.close');
          break;
        case 'zoom':
          buttonTitle = this.$t('photoSwipe.zoom');
          break;
        case 'arrowLeft':
          buttonTitle = this.$t('photoSwipe.arrowLeft');
          break;
        case 'arrowRight':
          buttonTitle = this.$t('photoSwipe.arrowRight');
          break;
      }

      return buttonTitle;
    }
  },
  computed: {}
};

export default PhotoSwipeGallery;
</script>
